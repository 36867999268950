import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
    Card,
    CardContent,
    CircularProgress, InputLabel,
    Link,
    MenuItem,
    Modal,
    Select, Autocomplete, TextField, Checkbox
} from "@mui/material";
import {useEffect, useState} from "react";
import Api, {postApi} from "../Api";
import {AccountBoxRounded, AssignmentInd, CalendarTodayRounded, History} from "@mui/icons-material";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {setProject} from "../features/SessionSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



const KanbanLane = ({id, title, items, rvid, scvd, rsn, ds}) => {
    const getListStyle = isDragOver => {
        return {
            margin:'2px', padding:1, boxShadow: '0px 0px 2px black', minHeight: '100%',
            backgroundColor: isDragOver ? '#263836' : '#263238',
        }
    }
    const getItemStyle = (isdragging, draggableStyle) => {
        return {
            marginTop: '5px',
            minHeight: '100px',
            userSelect: "none",
            flexDirection: 'row',
            ...draggableStyle
        }
    }
    const getDataCV = (i) => {
        let d = JSON.parse(i.data_cv)
        let st = {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            fontSize: '9pt'
        }
        let textColor = '#00ff00';
        let changeTextColor = '#00ff00';
        let changeFATextColor = '#00ff00';
        if (ds[0].timestamp > 0 && ds[0].id === 1 && ds[0].timestamp*1000 < moment() - moment(i.create_date)){
            textColor = "#ff0000";
        }
        if (ds[0].timestamp > 0 && ds[0].id !== 1 && ds[0].timestamp*1000 < moment() - moment(i.last_actions)){
            changeTextColor = "#ff0000";
        }
        if (moment(i.first_actions) - moment(i.create_date) > 3600000*24){
            changeFATextColor = "#ff0000";
        }
        return <div>
            <Typography sx={st} color={'yellow'}
                        style={{fontSize: '10pt'}}>{rvid(i.vacancy_id)}</Typography>
            <Typography title={'Никнейм'} sx={st}><AccountBoxRounded fontSize={'small'}/><span
                dangerouslySetInnerHTML={{__html: d.nickname}}/></Typography>
            <Typography title={'Время создания анкеты'} sx={st} color={textColor}><CalendarTodayRounded
                fontSize={'small'}/>{moment(i.create_date).format('DD.MM.YYYY HH:mm')}</Typography>
            {i.last_actions && <Typography title={'Время изменения анкеты'} sx={st} color={changeTextColor}><CalendarTodayRounded
                fontSize={'small'}/>{moment(i.last_actions).format('DD.MM.YYYY HH:mm')}
            </Typography>}
            {i.first_actions && <Typography title={'Время первого действия'} sx={st} color={changeFATextColor}><History
                fontSize={'small'}/>{moment(i.first_actions).format('DD.MM.YYYY HH:mm')}
            </Typography>}
            {i.user !== null && <Typography title={'Ответственный'} sx={st}><AssignmentInd
                fontSize={'small'}/>{i.user.Name}</Typography>}
        </div>
    }
    return <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', width: '250px', mt:1, padding:'1px',
        minHeight: '70vh', }} bgcolor={'transparent'}>
        <Typography variant={'subtitle2'} color={'orange'} sx={{minHeight:'3rem', p:1, m:'2px',
            boxShadow: '0px 0px 2px black', backgroundColor: '#263238'}}>{title} ({items.length})</Typography>
        <Droppable droppableId={`status_${id}`}>
        {(provided, snapshot) => (
            <Box {...provided.droppableProps}
                 ref={provided.innerRef}
                 sx={getListStyle(snapshot.isDraggingOver)}
            >
                    {items.map((i, key) => {
                        return <Draggable key={'q_' + key} draggableId={`cv_${i.id}`} index={key}>
                            {(provided, snapshot) => (
                                <Card
                                    variant={'elevation'}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <CardContent>
                                        <Typography fontSize={'10pt'} style={{cursor: 'pointer'}} onClick={() => {
                                            scvd(i)
                                        }}>#{i.id}</Typography>
                                        {getDataCV(i)}
                                    </CardContent>
                                </Card>
                            )}
                        </Draggable>
                    })}
                    {provided.placeholder}
                </Box>
        )}
        </Droppable>
    </Box>
}

const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto'

};



const HrDashboard = () => {
    const [cv, setCv] = useState([])
    const [statuses, setStatuses] = useState([])
    const [vac, setVac] = useState([])
    const [loading, setLoading] = useState(false)
    const [activeProject, setActiveProject] = useState('mfs')
    const [projects, setProjects] = useState([{shortname:'mfs', statuses: "[0]"}])
    const [cvData, setCvdata] = useState({assigned: 0})
    const [questions, setQuestions] = useState([])
    const [declineReason, setDeclineReason] = useState('')
    const [staff, setStaff] = useState([])
    const [assigned, setAssigned] = useState(0)
    const [onlymy, setOnlymy] = useState(false)
    const [inBlackList, setInBlackList] = useState("")
    const [blColor, setBlColor] = useState("#2eaa2e")
    const permissions = useSelector(state => state.permissions.value);
    const dispatch = useDispatch();

    const {viewcvid} = useParams();

    const getCv = async () => {
        setCv(await Api(`hr/cv/get-cv-project/${activeProject}`))
    }
    const getStatuses = async () => {
        setStatuses(await Api('hr/status/get-status'))
    }
    const getProjects = async () => {
        setProjects(await Api('hr/project/get-active-project'))
        setStaff(await Api(`admin/staff/list/hr/1`))
    }
    const getQuestions = async () => {
        setQuestions(await Api('hr/question/get-questions'))
    }
    const getVacancies = async () => {
        setLoading(true)
        //setVac(await Api(`hr/get-vacancies-project/${activeProject}`))
        setVac(await Api(`hr/vacancy/get-vacancy-short`))
        setLoading(false)
    }
    const updateStatus = async (id,status) => {
        postApi(`hr/cv/update-cv-status`,{id:id, status:status}).then(()=>{
            getCv();
        })
    }
    const blacklists = {
        1:'Черный список игроков',
        2:'Черный список администрации',
        3:'Черный список MFS RP',
        4:'Черный список TFS RP'
    }
    const checkBL = async (id) => {
        let bldata = await Api(`hr/blacklist/get-blacklist-steamid/${id}`)
        if(bldata.length){
            let message = bldata.map((i)=>`${blacklists[i.type]} - ${i.reason}`).toString()
            setInBlackList(message)
            setBlColor('#ff0000')
        }
        else{
            setInBlackList("В черных списках отсутствует")
            setBlColor("#2eaa2e")
        }
    }
    const projectStatuses = JSON.parse(projects.filter(i=>i.shortname === activeProject)[0].statuses);
    const lanes = statuses.filter((i)=>{
        return projectStatuses.indexOf(i.id) > -1
    }).map((i,e)=>[i.id, i.name,
        cv.filter((item)=>item.status===i.id &&(!item.user || (onlymy && item.user && item.user.SteamID === localStorage.getItem('myid')) || !onlymy))
    ])

    const resolveVacName = id => {
        for(let v of vac){
            if(v.id === id) return v.name;
        }
        return 'н/д';
    }

    const reorderCVs = (id, status) => {
        let ncv = structuredClone(cv);
        for(let c of ncv){
            if(c.id === id){c.status = status}
        }
        setCv(ncv);
    }

    const setActiveCv = async(id) => {
        setLoading(true);
        dispatch(setProject('hr'));
        await getVacancies();
        let cvdata = await Api(`hr/cv/get-cv-id/${id}`);
        setActiveProject(cvdata.project);
        setCvdata(cvdata)
        setLoading(false)
        window.history.pushState({},null,"/")
    }

    useEffect(()=>{
        if(permissions.indexOf('view_cv') > -1) {
            getCv();
            getStatuses();
            getVacancies();
            getProjects();
            getQuestions()

            if (viewcvid) {
                setActiveCv(viewcvid);
            }
        }
        //eslint-disable-next-line
    },[permissions])
    //eslint-disable-next-line
    useEffect(()=>{
        setCv([]);
        getCv();
        let updateCVTimer = -1;
        clearInterval(updateCVTimer)
        updateCVTimer = setInterval(getCv, 60000);
        return () => {clearInterval(updateCVTimer)}
        //eslint-disable-next-line
    },[activeProject])
    //eslint-disable-next-line
    useEffect(()=>{setAssigned(cvData.assigned ? cvData.assigned : 0)},[cvData])
    useEffect(()=>{
        if(cvData.hasOwnProperty('id')) {
            let jsdata = JSON.parse(cvData.data_cv);
            let steamid = jsdata.steam.split("/")[4]
            checkBL(steamid)
        }
        //eslint-disable-next-line
    },[cvData])
    const resolveQuestionName = id =>{
        for(let q of questions){ if (q.id === id) return q.text_cv}

    }
    const resolveQuestionShortName = id => {
        for(let q of questions){ if (q.id === id) return q.shortname}
    }
    const resolveQuestionType = id => {
        for(let q of questions){ if (q.id === id) return q.type}
    }
    const resolveStaffName = id => {
        for(let q of staff){ if (q.ID === id) return q.Name}
    }

    const declineCV = () => {
        postApi(`hr/cv/update-cv-comments`,{id: cvData.id, comments: declineReason}).then(()=>{
            postApi(`hr/cv/update-cv-status`, {id: cvData.id, status: 2}).then(()=>{getCv(); setCvdata({})});
        })
    }
    const acceptCV = () => {
        postApi(`hr/cv/update-cv-status`, {id: cvData.id, status: 4}).then(()=>{getCv()});
        setCvdata({})
    }
    const changeAssigned = () => {
        postApi(`hr/cv/update-cv-assigned`, {id: cvData.id, user_id: assigned}).then(getCv);
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const onDragEnd = e => {
        if(e.source.droppableId !== e.destination.droppableId && permissions.indexOf('change_cv_status') > -1){
            let cvid = parseInt(e.draggableId.split('cv_')[1]);
            let status = parseInt(e.destination.droppableId.split('status_')[1]);
            reorderCVs(cvid, status)
            updateStatus(cvid, status)
        }
    }


    const resolveCvDetails = () => {
        if(!cvData.hasOwnProperty('data_cv')){return <div/>}
        let vacancy = {}
        for(let v of vac){ if(v.id === cvData.vacancy_id){vacancy = v; break}}
        let v_questions = JSON.parse(vacancy.question)
        let jsdata = JSON.parse(cvData.data_cv);
        return <Box sx={{maxHeight: '70vh', overflowY: 'auto'}}>
            <Table size={'small'}>
                <TableBody>
                    <StyledTableRow>
                        <TableCell colSpan={2} align={'center'} sx={{color: 'yellow'}}>
                            Служебная информация
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Nickname</TableCell>
                        <TableCell>{jsdata.nickname}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>STEAM LINK</TableCell>
                        <TableCell><Link href={jsdata.steam} target={'_blank'}>{jsdata.steam}</Link></TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Присоединился к проекту</TableCell>
                        <TableCell>{jsdata.created || "Нет данных"}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Наличие в ЧС</TableCell>
                        <TableCell sx={{color: blColor}}>{inBlackList}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Подал анкету (раз):</TableCell>
                        <TableCell>{jsdata.amount || 1}</TableCell>
                    </StyledTableRow>
                    {v_questions.map((i,e)=>{
                        let qtype = resolveQuestionType(i);
                        let qname = resolveQuestionName(i)
                        let qa = jsdata[resolveQuestionShortName(i)]
                        return <StyledTableRow key={'q_'+e}>
                            {qtype ===5 &&
                                <TableCell colSpan={2} align={'center'} sx={{color: 'yellow'}}>
                                    {qname}
                                </TableCell>
                            }
                            {qtype !== 5 &&
                                <TableCell>{qname}</TableCell>
                            }
                            {qtype !==5 &&
                                <TableCell>{qa && qa.indexOf('http') > -1 ? <Link href={qa} target={'_blank'}>{qa}</Link> :qa}</TableCell>
                            }
                            </StyledTableRow>}
                    )}
                </TableBody>
            </Table>
        </Box>
    }
    return <div>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
        <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
            <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                <Typography variant={'h5'} sx={{p:2}}>Рабочий стол сотрудника ОП
                </Typography>
                {permissions.indexOf('view_cv') > -1 &&
                <Box sx={{ flexGrow: 0, p:2 }}>
                    <Select size={'small'}
                            value={activeProject}
                            onChange={(e)=>{setActiveProject(e.target.value)}}
                            sx={{width: '200px'}}
                    >
                        {projects.map((i,e)=><MenuItem key={'proj_'+e} value={i.shortname}>{i.name}</MenuItem>)}
                    </Select>
                </Box>
                }
                {permissions.indexOf('view_cv') > -1 &&
                    <Box sx={{ flexGrow: 1, p:2 }}>
                        <Checkbox checked={onlymy} onChange={()=>{setOnlymy(!onlymy)}}/>Только мои анкеты
                    </Box>
                }
            </Box>

        </Box>
        </Paper>
        {permissions.indexOf('view_cv') > -1 && !loading &&
        <Paper sx={{ width: '100%', mt:1, overflowX: 'scroll'}}>
                <DragDropContext onDragEnd={onDragEnd}>
                <Box sx={{display: 'flex', flex: 3}}>
                    {lanes.map((i,e)=> {
                            return <KanbanLane id={i[0]} title={i[1]} items={i[2]} key={'lane_' + e}
                                               rvid={resolveVacName} scvd={setCvdata} rsn={resolveStaffName}
                                               ds={statuses.filter(si=>si.id === i[0])}
                            />
                        }
                    )}
                </Box>
                </DragDropContext>
        </Paper>}
        {permissions.indexOf('view_cv') && loading && <div>
            <CircularProgress /> Подготавливается рабочее пространство
        </div>}
        {permissions.indexOf('view_cv') === -1 && <div>
            <Typography color={'error'}>У вас нет права доступа к этому инструменту</Typography>
        </div>
        }
        <Modal open={cvData.hasOwnProperty('id')}
               onClose={()=>{setCvdata({})}}>
            <Box sx={ModalStyle}>
                <Paper sx={{p:1}}>
                    {cvData.hasOwnProperty('id') &&
                    <Typography
                    variant={'h5'}
                    >
                        Анкета №{cvData.id} на должность {resolveVacName(cvData.vacancy_id)} ({cvData.status_detail.name})
                    </Typography>}
                </Paper>
                <Paper sx={{mt: 2, overflowY: 'auto'}}>
                    {resolveCvDetails()}
                    {permissions.indexOf('change_cv_assigned') > -1 &&
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{pl:2}}>
                                    <InputLabel id="change_assignee">Смена ответственного</InputLabel>
                                    <Select labelId="change_assignee"
                                            fullWidth
                                            value={assigned}
                                            onChange={(e)=>{setAssigned(e.target.value)}}
                                            label={'Смена ответственного'}
                                            sx={{flex: 1}}
                                    >
                                        <MenuItem value={0}>Нет ответственного</MenuItem>
                                        {staff.map((i,e)=><MenuItem key={'staff_assignee'+e} value={i.ID}>{i.Name}</MenuItem>)}
                                    </Select>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                    <Button onClick={changeAssigned} variant={'outlined'} color={'success'}>Сменить</Button>
                            </Grid>
                        </Grid>

                    }
                    {permissions.indexOf('change_cv_status') > -1 &&
                    <Grid container spacing={2} sx={{mt:2}}>
                        <Grid item xs={4}>
                            <Button sx={{m:2}} variant={'contained'} color={'success'} onClick={acceptCV}>Принять на должность</Button>
                        </Grid>
                        <Grid item xs={6}>
                                <Autocomplete
                                    id="decline-reason"
                                    freeSolo
                                    value={declineReason}
                                    onChange={(e,v)=>{setDeclineReason(v)}}
                                    onInputChange={(e,v)=>{setDeclineReason(v)}}
                                    options={cv.filter((e)=>e.status===2).map((i)=>i.hr_comments || '').filter((v,i,a)=>a.indexOf(v)===i)}
                                    renderInput={(params) => <TextField
                                        size={'small'}
                                        {...params}
                                        label={'Причина отклонения'}
                                        />}
                                />
                        </Grid>
                        <Grid item xs={2}>
                            <Button sx={{m:2}} variant={'contained'} color={'error'} onClick={declineCV}>
                                Отклонить
                            </Button>
                        </Grid>
                    </Grid>
                    }
                </Paper>
            </Box>
        </Modal>
    </div>
}

export default HrDashboard;